import * as React from 'react';

import { SignInForm } from '@/lib/auth/auth-devias/components/auth/custom/sign-in-form';
import { GuestGuard } from '@/lib/auth/auth-devias/components/auth/guest-guard';
import { SplitLayout } from '@/lib/auth/auth-devias/components/auth/split-layout';

// export const metadata: Metadata = {
//   title: `Sign in | Custom | Auth | ${config.site.name}`,
// };

export type PageProps = {
  onSubmit: (value: unknown) => Promise<{ error?: string }>;
};
export default function Page(props: PageProps): React.JSX.Element {
  return (
    <GuestGuard>
      <SplitLayout>
        <SignInForm onSubmit={props.onSubmit} />
      </SplitLayout>
    </GuestGuard>
  );
}

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';

export interface SplitLayoutProps {
  children: React.ReactNode;
}

export function SplitLayout({ children }: SplitLayoutProps): React.JSX.Element {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: '1fr 800px' },
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'var(--mui-palette-background-level1)',
          display: { xs: 'none', lg: 'flex' },
          flexDirection: 'column',
          p: 3,
        }}
      >
        <Stack spacing={4} sx={{ maxWidth: '400px' }}>
          <Stack spacing={1}>
            <Typography variant="h4">¡Te damos la Bienvenida!</Typography>
            <Typography color="text.secondary">
              CRAYON es una empresa Cordobesa, que cuenta con 30 años de trayectoria en el mercado de indumentaria para bebes y niños, de 0 a 14 años y que cada temporada propone looks diferentes.
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          boxShadow: 'var(--mui-shadows-8)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            justifyContent: 'center',
            p: 3,
          }}
        >
          <Box sx={{ maxWidth: '420px', width: '100%' }}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
}
